import React from 'react';
import { Form, NavLink } from 'react-router-dom';
import '../style/training.css';

import Workforce from '../images/img1.png';
import Contingent from '../images/img2.png';
import bpo from '../images/img3.png';

// Data 
const data = [
  {
    id: 1,
    id_name: 'ITtraining',
    image: Workforce,
    title: 'IT Training',
    desc_1: "Maintaining a technological edge in the highly competitive and disruptive Information Technology industry is a continuous process. Practical on-the-job training not only enhances business efficiency but helps you stay ahead of your competition. Our experts at NXT-Hire bring minimum years of experience and provide step-by-step guidance to help you achieve your goal.",
    desc_2: "Working with real-time projects helps you understand the nuances of how the industry works. NXT-Hire ensures that you get the necessary exposure to Live Projects while learning the ropes. Training programs designed by NXT-Hire ensure that you are industry-ready by the time you finish the course. Our real-time project exposure you are prepared to take up new roles and responsibilities as soon as you complete your training."
  },
  {
    id: 2,
    id_name: 'java',
    image: Contingent,
    title: 'Java',
    desc_1: "Java is specially designed for freshers who are aspiring careers in the field of Information Technology. The syllabus of this training is designed by industry experts by keeping an eye on actual industry requirements. Our major focus is on clearing concepts and giving hands-on practical knowledge along with Global Certification Knowledge; so that, our students can strongly step-in in into the IT industry.",
    desc_2: "Right from day one of the coding programs, you’ll be trained to think and build like a software engineer and cultivate a think-business-first mindset. By the end of our programs, you will develop the key skills to conquer your career."
  },
  {
    id:3,
    id_name: 'UIFullStack',
    image: bpo,
    title: 'UI Full-Stack',
    desc_1: "We at NXT-Hire can help you master the front-end and build a remarkable career in the IT industry.Our intensive training the course is designed to help students learn UI Full Stack from scratch and develop robust and scalable network applications. Our focus is to create competent UI developers who are ready to be a part of the IT world. Our training program will take you froma beginner to an intermediate UI developer.",
    desc_2: "This course is for designers and developers, or for anyone who wants to become one. As a designer you will learn the essential skills and thinking patterns to build the things you design."
  },
  {
    id:4,
    id_name: 'UIUX',
    image: Workforce,
    title: 'UI & UX',
    desc_1: "UI (user interface) / UX (user experience) describes a set of concepts, guidelines, and workflows for critically thinking about the design and use of an interactive product. UI/UX is a growing profession in the geospatial industry and broader the technology sector, with UI/UX designers, needed to engage with stakeholders and target users throughout large software engineering and web design projects.",
    desc_2: " As a developer you will learn various design principles and how to improve both the usability and accessibility of the components you're already building."
  },
  {
    id:5,
    id_name: 'GraphicsDesign',
    image: Contingent,
    title: 'Graphics Design Course',
    desc_1: "Graphic design is the process of visual communication through the use of typography, photography, iconography and illustration. The field is considered a subset of visual communication and communication design, but sometimes the term “graphic design” is used synonymously.",
    desc_2: "Graphic design is a profession, academic discipline and applied art whose activity consists in projecting visual communications intended to transmit specific messages to social groups, with specific objectives."
  }
]

function training() {
  return (
    <div className='solution'>

<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>IT Training</h3>
  <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><NavLink className="" to="/">Home</NavLink></li>
    <li className="breadcrumb-item active" aria-current="page">IT Training</li>
  </ol>
</nav>  
  </div>
  </div>
</div>

    <div className='container'>
       <div className='whyus-content'>
    
       {
       data.map(({id, id_name, image, title, desc_1,desc_2}) => {
         return (
       <div  key={id} className='row mt-5 mb-5' id={id_name}>
         <div className='whyus-content-div col-sm-5 col-12'>
            <div className='bg-img' style={{backgroundImage:`url(${image})`}}></div>
        </div>
        <div className='content-div col-sm-7 col-12'>
            <div className='detail'>
                <h3>{title}</h3>
                <p>{desc_1}</p>
                <p>{desc_2}</p>
                {/* <NavLink className="my-btn btn my-btn-2" to="/training">Get Free Training</NavLink>
                <NavLink className="my-btn btn" to="/contact">Contact Us</NavLink> */}
            </div>
        </div>
        </div>
         )
        }

        )}
     </div>
    </div>





    </div>
  )
}

export default training