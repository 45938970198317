import React from 'react';
import '../style/job.css';
import { NavLink } from 'react-router-dom'

function faq() {
  return (
<div className='job'>
 
<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>Frequently Asked Questions</h3>
  <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><NavLink className="" to="/">Home</NavLink></li>
    <li className="breadcrumb-item active" aria-current="page">FAQ</li>
  </ol>
</nav>  
  </div>
  </div>
</div>


<div className=''>
    <div className='container'>
      <div className="accordion" id="accordionExample">

  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Q1. We understand and feel your pain
        </button>
      </h2>
    </div>

    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et nisl hendrerit, aliquet mi sed, scelerisque tortor. Aliquam eu scelerisque quam, ac tristique dolor. Aliquam nulla risus, fermentum feugiat tortor quis, facilisis cursus arcu. Sed eu purus placerat, aliquet augue nec.
      </div>
    </div>
  </div>

  <div className="card">
    <div className="card-header" id="headingTwo">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Q2. We help you connect to the right support
        </button>
      </h2>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div className="card-body">
      Donsectetur adipiscing elit. Morbi et nisl hendrer erisque to it, aliquet mi sed, scelerisque tortor. Aliquam eu scelerisque quam, ac tristique dolor. Aliquam nulla risus, fermentum feugiat tortor quis, facilisis cursus arcu. Sed eu purus placerat, aliquet augue.
      </div>
    </div>
  </div>

<div className="card">
  <div className="card-header" id="headingTwo">
    <h2 className="mb-0">
      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Q3. We adapt to your needs
      </button>
    </h2>
  </div>
  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
    <div className="card-body">
    Donsectetur adipiscing elit. Morbi et nisl hendrer erisque to it, aliquet mi sed, scelerisque tortor. Aliquam eu scelerisque quam, ac tristique dolor. Aliquam nulla risus, fermentum feugiat tortor quis, facilisis cursus arcu. Sed eu purus placerat, aliquet augue.
    </div>
  </div>
</div>

</div>

    </div>
</div>

</div>
  )
}

export default faq