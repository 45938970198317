import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../images/logo.png'
import { HashLink } from 'react-router-hash-link';

const header = () => {

  return (
  <header className=''>
  <div className="container">

  <nav className="navbar navbar-expand-sm  navbar-light">
  <a className="navbar-brand" href="/"><img className="logo" src={logo} alt="logo" /> NXT-Hire</a>
 
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="collapsibleNavbar">
  <ul className="navbar-nav ml-auto">
    
    <li className="nav-item">
      <NavLink className="nav-link" to="/about">About</NavLink>
    </li>
   
  <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
      Our Solution
      </a>
      <div className="dropdown-menu">
        <div className='mini_nav'>
          <HashLink className="dropdown-item" to="/workforce">Workforce Management</HashLink>
         <div className='mini_menu'>
         <HashLink className="dropdown-item" to="/workforce/#contingent">Contingent Staffing</HashLink>
         <HashLink className="dropdown-item" to="/workforce/#ITStaffing">IT Staffing</HashLink>
         <HashLink className="dropdown-item" to="/workforce/#ProjectStaffing">Project Staffing</HashLink>
         <HashLink className="dropdown-item" to="/workforce/#SkillsLearning">SKills & Learning</HashLink>
         <HashLink className="dropdown-item" to="/workforce/#PayrollTransmission">Pay roll and Transmission</HashLink>
         </div>
         </div>
        <div className='mini_nav'>
        <HashLink className="dropdown-item" to="/global">Global Technology</HashLink>
         <div className='mini_menu'>
        <HashLink className="dropdown-item" to="/global/#BPO">BPO</HashLink>
        <HashLink className="dropdown-item" to="/global/#DomesticInternational">Domestic & Internation IT</HashLink>
        <HashLink className="dropdown-item" to="/global/#Jobs">Jobs</HashLink>
         </div>
         </div>
      </div>
    </li>
    
    <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
      IT Training
      </a>
      <div className="dropdown-menu">
        <HashLink className="dropdown-item" to="/training">Support</HashLink>
        <HashLink className="dropdown-item" to="/training">Job Support</HashLink>
        <HashLink className="dropdown-item" to="/training">Interview Support</HashLink>
        <HashLink className="dropdown-item" to="/training/#ITtraining">Tech Training</HashLink>
        <HashLink className="dropdown-item" to="/training/#java">Java</HashLink>
        <HashLink className="dropdown-item" to="/training/#ITtraining">Dotnet</HashLink>
        <HashLink className="dropdown-item" to="/training/#UIFullStack">UI Full-Stack</HashLink>
        <HashLink className="dropdown-item" to="/training/#UIUX">UI & UX</HashLink>
        <HashLink className="dropdown-item" to="/training/#GraphicsDesign">Graphic Design Course</HashLink>
      </div>
    </li>

    <li className="nav-item">
      <NavLink className="nav-link" to="/job">Career</NavLink>
    </li>
    
    <li className="nav-item">
      <NavLink className="nav-link" to="/contact">Contact</NavLink>
    </li>
  </ul> 
    </div>
  </nav>

  </div>
  </header>

  )
}

export default header