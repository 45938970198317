import React from 'react';
import '../style/job.css';
import { NavLink } from 'react-router-dom';

function job() {
  return (
<div className='job'>
 
<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>Career</h3>
  <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><NavLink className="" to="/">Home</NavLink></li>
    <li className="breadcrumb-item active" aria-current="page">Career</li>
  </ol>
</nav>  
  </div>
  </div>
</div>


<div className=''>
    <div className='container'>
       
  <div className="accordion" id="accordionExample">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Full Stack Software Developer
        </button>
      </h2>
    </div>

    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
      <p>We are looking for experienced Full Stack Software Developers and Engineers who are Agile, values-driven and enjoy solving interesting problems. We work in highly-collaborative, self-organising teams and are looking for like-minded people to join us.</p>
      <b>Key responsibilities: </b>
      <ul>
        <li>Build bespoke applications using Ruby on Rails</li>
        <li>Manage and configure our flexible infrastructure stack including AWS, Docker and Kubernetes</li>
        <li>Build and improve our Continuous Integration services (Github Actions and Gitlab Pipeline) running tests, linters, security and accessibility audits and deployments</li>
        <li>Develop high quality and well-tested code</li>
        <li>Work in a small, dedicated and high-performing Agile software team</li>
        <li>Work closely with Product Owners and our UX/UI designers</li>
        <li>Continuously learn and share knowledge to grow the development team capabilities</li>
        <li>Build strong relationships with Boost team members and clients</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingTwo">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Human Resources Administrator
        </button>
      </h2>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div className="card-body">
      <p>We are looking for experienced Full Stack Software Developers and Engineers who are Agile, values-driven and enjoy solving interesting problems. We work in highly-collaborative, self-organising teams and are looking for like-minded people to join us.</p>
      <b>Key responsibilities: </b>
      <ul>
        <li>Build bespoke applications using Ruby on Rails</li>
        <li>Manage and configure our flexible infrastructure stack including AWS, Docker and Kubernetes</li>
        <li>Build and improve our Continuous Integration services (Github Actions and Gitlab Pipeline) running tests, linters, security and accessibility audits and deployments</li>
        <li>Develop high quality and well-tested code</li>
        <li>Work in a small, dedicated and high-performing Agile software team</li>
        <li>Work closely with Product Owners and our UX/UI designers</li>
        <li>Continuously learn and share knowledge to grow the development team capabilities</li>
        <li>Build strong relationships with Boost team members and clients</li>
        </ul>
      </div>
    </div>
  </div>
</div>

    </div>
</div>

</div>
  )
}

export default job