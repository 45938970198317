import './style/main.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Header, Footer} from './components';
import {Home, About, Contact, Workforce, Global, Training, Job, Faq} from './pages';
import './style/media.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
       <Header />
        <Routes>
         <Route path="/" exact element={<Home />} />
         <Route path="/about" exact element={<About />} />
         <Route path="/contact" exact element={<Contact />} />
         <Route path="/workforce" exact element={<Workforce />} />
         <Route path="/global" exact element={<Global />} />
         <Route path="/training" exact element={<Training />} />
         <Route path="/job" exact element={<Job />} />
         <Route path="/faq" exact element={<Faq />} />
        </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
