import React from 'react';
import Whatsapp from '../images/whatsapp.png';

function popup(props) {
  return (props.trigger) ? (
      <div className="overlay">
      <div className="popup">
          {/* <h5>Connect With Us For Instant Response <span className="close" onClick={() =>props.setTrigger(false)}>&times;</span></h5>
          <p>Send us your query here and we will get to you in shortest time possible.</p> 
          <a href="https://wa.me/+919075119976" target="_blank"><i class="bi bi-whatsapp"></i>&nbsp; WhatsApp</a>*/}

<a href="https://wa.me/+919075119976" target="_blank">
  <img src={Whatsapp} alt="whatsapp" />
  <div className='whatsapp_tip'>
  <h5>Connect With Us For Instant Response</h5>
  <p>Send us your query here and we will get to you in shortest time possible.</p>
  </div>
</a>
          
      </div>
      </div>
  ) : "";
}

export default popup