import React from 'react'
import {NavLink} from 'react-router-dom'
import '../style/footer.css'
import logo from '../images/logo-b.png'
import {Popup} from '../components';
import { useState, useEffect } from 'react';


function Footer() {

  //For popup timer
  const [timedPopup, setTimedPopup] = useState(false);
  useEffect (() => {
      setTimeout(() => {
          setTimedPopup(true);
      },3000);
  }, []);
  
  return (
    
    <footer className="py-5">

 <Popup trigger={timedPopup} setTrigger={setTimedPopup} />
 
    <div className="container">
      <div className="row">
        <div className="col-sm-4 col-12">
        <img className="logo" src={logo} alt="logo" />
          <p>
          A Startup firm committed to maximizing global workforce solutions on behalf of its clients. We have a focused approach toward staffing services and consulting solutions.NXT-Hire provides end-to-end transformative services and promises to deliver our commitment. We have a team of Energetic and Enthusiastic young professionals.
          </p>
        </div>
        <div className="col-sm-1 col-4"></div>
  
        <div className="col-sm-2 col-12">
          <h5>Extra Links</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><NavLink to="/faq" className="nav-link p-0">FAQ</NavLink></li>
            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0">Privacy Policy</NavLink></li>
            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0">Testimonials</NavLink></li>
            <li className="nav-item mb-2"><NavLink to="/contact" className="nav-link p-0">Contact</NavLink></li>
          </ul>
        </div>

        <div className="col-sm-1 col-4"></div>
  
        <div className="col-sm-4 col-12">
          <form>
            <h5>Subscribe to our newsletter</h5>
            <p>Monthly digest of whats new and exciting from us.</p>
            <div className="d-flex w-100 gap-2">
              <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
              <button className="btn" type="button">Subscribe</button>
            </div>
          </form>
        </div>
      </div>
  
      <div className="d-flex justify-content-between py-2 my-2 border-top">
        <p>© 2023 Company, Inc. All rights reserved.</p>
        <ul className="list-unstyled d-flex">
          <li className="mr-3"><a href="#"><i class="bi bi-facebook"></i></a></li>
          <li className="mr-3"><a href="#"><i class="bi bi-linkedin"></i></a></li>
          <li className="mr-3"><a href="#"><i class="bi bi-twitter"></i></a></li>
        </ul>
      </div>
      </div>

    </footer>

  )
}

export default Footer