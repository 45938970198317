import React from 'react';
import { NavLink } from 'react-router-dom';
import {Slider} from '../components';
import { sliderData } from '../components/sliderData';
import '../style/home.css';

function Home() {

  return (
   <div className='home'>

   

    <Slider slides={sliderData} />
    <div className='container info'>
        <h3>We open you up to a pool of possibilities</h3>
        <p>Have questions or need immediate assistance contact us</p>
        <span>Email: contact@nxthire.com | Mobile: +91 90751 19976</span>
    </div>

     <div className='container main-service'>
       <div className='row'>

        <div className='main-service-div col-sm-4 col-12'>
            <NavLink to="/global">
            <div className='bg-img'></div>
            <div className='detail'>
                <h3>Interview Support</h3>
                <p>Maintaining a technological edge in the highly competitive and disruptive Information Technology industry is a continuous process. Practical on-the-job training not only enhances business efficiency but helps you stay ahead of your competition.</p>
            </div>
            </NavLink>
        </div>

        <div className='main-service-div col-sm-4 col-12'>
            <NavLink to="/job">
            <div className='bg-img'></div>
            <div className='detail'>
                <h3>Job Support</h3>
                <p>We focus on the needs of our clients and our deployed workforce working as one to deliver on our promises and commitments. We understand the challenges of your current job profile and your busy schedule. </p>
            </div>
            </NavLink>
        </div>

        <div className='main-service-div col-sm-4 col-12'>
            <NavLink to="/training">
            <div className='bg-img'></div>
            <div className='detail'>
                <h3>On-Demand Training</h3>
                <p>The backbone of any organization and with the right people, you can transform Human Talent into Digital Acquisition. We aim to do just that equip companies with top-class workforce management solutions.</p>
            </div>
            </NavLink>
        </div>

   </div>
</div>


    <div className='whyus-div'>

    <figure className="bg-pattern">
		<svg className="rotate-74 fill-color opacity-1">
			<circle cx="180.4" cy="15.5" r="7.7"></circle>
			<path d="m159.9 22.4c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<ellipse transform="matrix(.3862 -.9224 .9224 .3862 71.25 138.08)" cx="139.4" cy="15.5" rx="6.1" ry="6.1"></ellipse>
			<circle cx="118.9" cy="15.5" r="5.4"></circle>
			<path d="m98.4 20.1c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2.1 4.6-4.6 4.6z"></path>
			<path d="m77.9 19.3c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8z"></path>
			<path d="m57.3 18.6c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 17.8c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="15.5" r="1.6"></circle>
			<circle cx="180.4" cy="38.5" r="7.7"></circle>
			<path d="m159.9 45.3c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<ellipse transform="matrix(.8486 -.5291 .5291 .8486 .7599 79.566)" cx="139.4" cy="38.5" rx="6.1" ry="6.1"></ellipse>
			<circle cx="118.9" cy="38.5" r="5.4"></circle>
			<path d="m98.4 43.1c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2.1 4.6-4.6 4.6z"></path>
			<circle cx="77.9" cy="38.5" r="3.8"></circle>
			<path d="m57.3 41.5c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1c0 1.8-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 40.8c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="38.5" r="1.6"></circle>
			<circle cx="180.4" cy="61.4" r="7.7"></circle>
			<path d="m159.9 68.3c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<ellipse transform="matrix(.3862 -.9224 .9224 .3862 28.902 166.26)" cx="139.4" cy="61.4" rx="6.1" ry="6.1"></ellipse>
			<circle cx="118.9" cy="61.4" r="5.4"></circle>
			<path d="m98.4 66c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6c0 2.6-2.1 4.6-4.6 4.6z"></path>
			<path d="m77.9 65.2c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8z"></path>
			<path d="m57.3 64.5c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 63.7c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="61.4" r="1.6"></circle>
			<circle cx="180.4" cy="84.4" r="7.7"></circle>
			<path d="m159.9 91.3c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<path d="m139.4 90.5c-3.4 0-6.1-2.7-6.1-6.1s2.7-6.1 6.1-6.1 6.1 2.7 6.1 6.1c0 3.3-2.7 6.1-6.1 6.1z"></path>
			<circle cx="118.9" cy="84.4" r="5.4"></circle>
			<path d="m98.4 89c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2.1 4.6-4.6 4.6z"></path>
			<path d="m77.9 88.2c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8z"></path>
			<path d="m57.3 87.4c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1c0 1.8-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 86.7c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="84.4" r="1.6"></circle>
			<circle cx="180.4" cy="107.3" r="7.7"></circle>
			<path d="m159.9 114.2c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<path d="m139.4 113.4c-3.4 0-6.1-2.7-6.1-6.1s2.7-6.1 6.1-6.1 6.1 2.7 6.1 6.1-2.7 6.1-6.1 6.1z"></path>
			<circle cx="118.9" cy="107.3" r="5.4"></circle>
			<path d="m98.4 111.9c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6c0 2.6-2.1 4.6-4.6 4.6z"></path>
			<path d="m77.9 111.2c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8z"></path>
			<path d="m57.3 110.4c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 109.6c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3c0.1 1.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="107.3" r="1.6"></circle>
			<circle cx="180.4" cy="130.3" r="7.7"></circle>
			<path d="m159.9 137.2c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<ellipse transform="matrix(.3862 -.9224 .9224 .3862 -34.62 208.52)" cx="139.4" cy="130.3" rx="6.1" ry="6.1"></ellipse>
			<circle cx="118.9" cy="130.3" r="5.4"></circle>
			<path d="m98.4 134.9c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2.1 4.6-4.6 4.6z"></path>
			<path d="m77.9 134.1c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8z"></path>
			<path d="m57.3 133.4c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 132.6c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="130.3" r="1.6"></circle>
			<circle cx="180.4" cy="153.2" r="7.7"></circle>
			<path d="m159.9 160.1c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<ellipse transform="matrix(.3862 -.9224 .9224 .3862 -55.794 222.61)" cx="139.4" cy="153.2" rx="6.1" ry="6.1"></ellipse>
			<circle cx="118.9" cy="153.2" r="5.4"></circle>
			<path d="m98.4 157.8c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6c0 2.6-2.1 4.6-4.6 4.6z"></path>
			<circle cx="77.9" cy="153.2" r="3.8"></circle>
			<path d="m57.3 156.3c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 155.5c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="153.2" r="1.6"></circle>
			<circle cx="180.4" cy="176.2" r="7.7"></circle>
			<path d="m159.9 183.1c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9z"></path>
			<ellipse transform="matrix(.3862 -.9224 .9224 .3862 -76.968 236.7)" cx="139.4" cy="176.2" rx="6.1" ry="6.1"></ellipse>
			<circle cx="118.9" cy="176.2" r="5.4"></circle>
			<path d="m98.4 180.8c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2.1 4.6-4.6 4.6z"></path>
			<path d="m77.9 180c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8z"></path>
			<path d="m57.3 179.3c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1z"></path>
			<path d="m36.8 178.5c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3z"></path>
			<circle cx="16.3" cy="176.2" r="1.6"></circle>
		</svg>        
	</figure>

    <div className='container'>
       <div className='whyus-content'>
       <div className='row'>
         <div className='whyus-content-div col-sm-3 col-12'>
            <div className='bg-img'>
                <figure className='f-circle'>
                <svg width="141px" height="141px">
				<path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z"></path>
				</svg>
                </figure>
            </div>
        </div>
        <div className='whyus-content-div col-sm-5 col-12'>
            <div className='detail'>
                <h3>Why people trust us</h3>
                <p>A Startup firm committed to maximizing global workforce solutions on behalf of its clients. We have a focused approach toward staffing services and consulting solutions. NXT-Hire provides end-to-end transformative services and promises to deliver our commitment. We have a team of Energetic and Enthusiastic young professionals.</p>
                <NavLink to='/workforce' className='btn my-btn'>Learn More</NavLink>
            </div>
        </div>
       </div>
       </div>

       <div className='whyus-content'>
       <div className='row justify-content-end'>
        <div className='whyus-content-div col-sm-5 col-12'>
            <div className='detail'>
                <h3>A One-Step platform</h3>
                <p>Our goal is to work closely with our clients and help them achieve their ambitions. Our approach revolves around exceptional client service and the key to our success has been the result of the deep relationships we have built with our clients. our goal is to be considered as an extension of your team rather than an independent third party.</p>
                <NavLink to='/training' className='btn my-btn'>Learn More</NavLink>
            </div>
        </div>
         <div className='whyus-content-div col-sm-3 col-12'>
            <div className='bg-img'>
            <figure className='f-circle'>
                <svg width="141px" height="141px">
				<path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z"></path>
				</svg>
                </figure>
            </div>
        </div>
       </div>
       </div>
    </div>
    </div>


    <div className='video-div'>
    <div className='container'>
       <div className='whyus-content'>
       <div className='row'>
         <div className='whyus-content-div col-sm-5 col-12'>
         {/* <iframe class="embed-responsive-item"
    src="https://www.youtube.com/embed/TUF4lL-d-AA?rel=0" 
    allowfullscreen>
</iframe> */}
            <div className='bg-img'></div>
        </div>
        <div className='whyus-content-div col-sm-7 col-12'>
            <div className='detail'>
                <h3>Get matched to your dream opportunity</h3>
                <p>We provide integrated IT training services and a complete range of IT consulting support in Java Full stacks, UI full-stack, Java courses, Node JS, UI &amp; UX, Graphics Design courses, and other technologies to cater to all the requirements.</p>
                <NavLink to='/contact' className='btn my-btn'>Contact Us</NavLink>
            </div>
        </div>
       </div>
       </div>
    </div>
    </div>
    

</div>

  )
}

export default Home