import React from 'react';
import { Form, NavLink } from 'react-router-dom';
import '../style/training.css';

import Workforce from '../images/img1.png';
import Contingent from '../images/img2.png';
import bpo from '../images/img3.png';

// Data 
const data = [
  {
    id: 2,
    id_name: 'contingent',
    image: Contingent,
    title: 'Contingent Staffing',
    desc_1: "NXT-Hire provides the most innovative, efficient, and cost-effective workforce solutions in today is marketplace. NXT-Hire leverages its mature, quality-certified processes to provide a variety of standard and customized solutions and programs to help clients optimize their supply chain and increase time to market their workforce requirements.",
    desc_2: "NXT-Hire workforce solutions help clients increase efficiency, accelerate project progress, cut costs, and ultimately enhance their competitive edge. NXT-Hire provides technical expertise to fill gaps in clients' immediate skill sets availability, delivers emerging technology skill-sets, refreshes the existing skill base, allows for flexibility in project planning and execution phases, and provides budgeting/financial flexibility by offering contingent labor as a variable cost."
  },
  {
    id:3,
    id_name: 'ITStaffing',
    image: bpo,
    title: 'IT Staffing',
    desc_1: "NXT-Hire has always provided itself with delivering the best of services and gaining high customer satisfaction. Our Staffing Services are designed to help you find the most effective resources to bridge the tech skill gaps within your organization. Our offerings include service providers, needs-based staffing, and end-to-end staffing solutions.",
    desc_2: "Needs-Based Staffing: Our expert recruiters help meet your staffing needs by managing your growing demands efficiently.    Service Provider: We streamline your vendor engagement through cutting-edge proprietary digital tools and a seasoned, expert team. This ensures quality vendor management services and timely availability of resources for your needs.    End-to-End Staffing Solutions: Our comprehensive staffing solutions are aimed at meeting the requirements of Hire, Train, Deploy, Build, Operate, and Transfer."
  },
  {
    id:4,
    id_name: 'ProjectStaffing',
    image: Contingent,
    title: 'Project Staffing',
    desc_1: "NXT-Hire differentiates itself by focusing on its core competency – staff augmentation and then provides added value through the involvement of its dedicated Solutions practice.    The subject matter expertise found in the Solutions practice combined with the maturity of our processes, platforms, and technology allows NXT-Hire, with scale, to deliver bulk staffingprograms that deliver value, cost efficiencies, and most importantly, results. The ability to take on deliverables and management responsibility provides NXT-Hire clients with more choice in how they achieve their internal objectives.",
    desc_2: "Even in cases where our clients prefer to manage the resources directly, the Solutions team bolsters our own recruiting efforts by ensuring that screening is done by actual delivery experts who operate in that space.In addition, the Solutions team can also serve as a touchpoint to the staffed resources, offering advice and support on achieving compliance with client expectations and industry best practices."
  },
  {
    id:5,
    id_name: 'SkillsLearning',
    image: Workforce,
    title: 'Skills & Learning Solutions',
    desc_1: "At NXT-Hire, we have developed content and courses in the following Sectors as per the mandates set forth by the respective Sector Skill Councils. The content is developed in consultation with the subject matter experts with the stringent procedure in place to ensure the accuracy and quality of the content.",
    desc_2: " All our trainers have been certified by the industryexperts and are equipped with the right skills to ensure effective training delivered."
  },
  {
    id:6,
    id_name: 'PayrollTransmission',
    image: Contingent,
    title: 'Payroll & Transmission Services',
    desc_1: "Payroll Services: NXT-Hire provides payrolling services, assuming management responsibility of the referred or pass-through consultants identified by the client. NXT-Hire payrolling process includes complete validation, induction, and communication management.",
    desc_2: "Transitioning: Through a successful vendor consolidation program, clients of NXT-Hire gain the advantages of engaging with fewer, more qualified suppliers.  Benefits include Centralized sourcing activities for greater efficiency. No Fee disparity,No contact variant limited candidate ownership questions for less co-employment risk consistent deliverables."
  },
  {
    id:7,
    id_name: 'GlobalTechnology',
    image: Workforce,
    title: 'Global Technology',
    desc_1: "At NXT-Hire, we think future-forward. We realize that technology is the bedrock of innovation and what drives tomorrow. So, we harness the power of technology to drive productivity and optimal efficiency into your business processes.",
    desc_2: " Through our range of technology solutions that include BPO Services, Domestic and International IT Services, and Jobs solutions, we strive to deliver excellence across various facets of the enterprise."
  },
  {
    id:8,
    id_name: 'BPO',
    image: bpo,
    title: 'BPO',
    desc_1: "We include payment processing, information technology services, quality assurance, etc. We also include marketing, sales, customer relations, and grievance redressal.",
    desc_2: "Instead of buying IT equipment and hiring more employees to do different tasks, NXT-Hire will reduce or even eliminate overhead costs. We also adopt best practices and use the latest technology. It naturally results in higher efficiency and greater productivity. Transferring non-core processes to us helps your organization more time to focus on your main business activities. With local market knowledge, national law expertise, or fluency in a foreign language we will help in boosting efficiency and quicker expansion."
  },
  {
    id:9,
    id_name: 'DomesticInternational',
    image: Workforce,
    title: 'Domestic & International IT Services',
    desc_1: "Focused on Information Technology, NXT-Hire is a major service provider when it comes to providing B2B companies with the tools and applications they need to effectively compete in the ever-evolving markets.",
    desc_2: " We are one of the major providers of integrated services through Application, BPO, Testing, and Analytics as well as enablers in the domains of Cloud, DevOps, and Information Security. We assist organizations with the tools and applications they need to effectively compete in the ever-changing and challenging market. At NXT-Hire, we enable an organization to seamlessly run an IT platform by integrating cognitive solutions, analytics, and automation with best-in-class IT services and people skills."
  },
  {
    id:10,
    id_name: 'Jobs',
    image: Contingent,
    title: 'Jobs',
    desc_1: "Our mission is to connect, empower, and support the advancement of all gender by building a gender-balanced workforce. We seek to embrace and value the varied intellectual competencies and the invaluable experiences that individuals possess. At NXT-Hire, we provide a fun, dynamic, performance-oriented work environment, based on the principles of mutual respect, open communication, trust, and mutual accountability.",
    desc_2: "We focus on the needs of our clients and our deployed workforce working as one to deliver on our promises and commitments. We understand the challenges of your current job profile and your busy schedule. With this in mind, NXT-Hire ensures that our experts are available on flexible days and times so that you can learn at your own flexible time. We enable our team to maximize their intellect by providing unlimited opportunities for growth."
  }
]



function training() {
  return <div className='solution'>

<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>Workforce Management</h3>
  <p>The workforce is the backbone of any organization and with the right people, you can transform Human Talent into Digital Acquisition. At NXT-Hire, we aim to do just that equip companies with top-class workforce management solutions to help them succeed.</p>
  <p>As a well-recognized workforce management services provider, we offer end-to-end HRO services that are aimed at solving complex HR challenges.From customized staffing services, expert talent acquisition, search and recruitment, payroll compliance, training and skill development, to manage workforce solutions, NXT-Hire provides all the tools necessary to drive your business growth.</p>
  </div>
  </div>
</div>

    <div className='container'>
       <div className='whyus-content'>
    
       {
       data.map(({id, id_name, image, title, desc_1,desc_2}) => {
         return (
       <div  key={id} className='row mt-5 mb-5' id={id_name}>
         <div className='whyus-content-div col-sm-5 col-12'>
            <div className='bg-img' style={{backgroundImage:`url(${image})`}}></div>
        </div>
        <div className='content-div col-sm-7 col-12'>
            <div className='detail'>
                <h3>{title}</h3>
                <p>{desc_1}</p>
                <p>{desc_2}</p>
                {/* <NavLink className="my-btn btn my-btn-2" to="/training">Get Free Training</NavLink>
                <NavLink className="my-btn btn" to="/contact">Contact Us</NavLink> */}
            </div>
        </div>
        </div>
         )
        }

        )}
     </div>
    </div>




  </div>
}

export default training