import slider1 from '../images/slider1.png';
import slider2 from '../images/slider2.png';
import slider3 from '../images/slider3.png';

export const sliderData = [
  {
    id: "1",
    img: slider1,
    title: "We will help you getting the perfect job.",
    disc: "We provide best job support bi-weekly or monthly based on your requirement.",
    btn: "Get In Touch",
  },
  {
    id: "2",
    img: slider2,
    title: "We help you connect to the right support.",
    disc: "We help you connect to the right freelancer with require skillset on-demand at the earliest. We have been helping many IT professionals like you",
    btn: "Get In Touch",
  },
  {
    id: "3",
    img: slider3,
    title: "We adapt to your needs .",
    disc: "We serve you based on your requirements and source the best available support at the earliest.",
    btn: "Get In Touch",
  },
]