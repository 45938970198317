import React from 'react';
import { NavLink } from 'react-router-dom';
import '../style/contact.css';


function training() {
  return (
    <div className='contact'>

<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>Contact Us</h3>
  </div>
  </div>
</div>

    <div className='container'>
      <div className='row'>

    <div className='col-md-4 col-12'>
    <div className="contact-info">
          <h3>Quick Find Us</h3>
          <ul>
            <li>
              <h5>Email</h5>
              <a href='mailto:contact@nxthire.com' target='_blank'>contact@nxthire.com</a>
            </li>
            <li>
              <h5>Available Hours</h5>
              <span>Monday-Saturday 9am-6pm</span>
            </li>
            <li>
              <h5>Address</h5>
              <span>007 Liverpool Street<br/>Auckland C.B.D., India</span>
            </li>
          </ul>     
    </div>
    </div>


    <div className='col-md-8 col-12'>
      <div className='row contact-info-div'>
    <div className='col-md-6 col-12'>
    <div className="contact-info">
          <h3>General Information</h3>
          <p>Our belief in the benefits of working closely with clients, as partners are reflected in everything we do and our goal is to be considered as an extension to your team rather than an independent third party.</p>  
          <figure className='f-circle'>
          <svg width="141px" height="141px">
				  <path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z"></path>
				  </svg>
          </figure>   
    </div>
    </div>

    <div className='col-md-6 col-12'>
    <div className="contact-info">
          <h3>Help & Support</h3>
          <p>Send us your query here and we will get to you in shortest time possible.</p>
          <a href="https://wa.me/+919075119976" target="_blank" className='whatsapp' ><i className="bi bi-whatsapp"></i>&nbsp; WhatsApp</a> 
          <figure className='f-circle'>
          <svg width="141px" height="141px">
				  <path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z"></path>
				  </svg>
          </figure>        
    </div>
    </div>

    <div className='social'>
      <ul className="d-flex">
          <li><a href="https://facebook.com/" target='_blank'><i className="bi bi-facebook"></i></a></li>
          <li><a href="https://linkedin.com/" target='_blank'><i className="bi bi-linkedin"></i></a></li>
          <li><a href="https://twitter.com/" target='_blank'><i className="bi bi-twitter"></i></a></li>
      </ul>
    </div>

    </div>
    </div>      

    </div>  
    </div>


    </div>
  )
}

export default training