import React from 'react';
import { Form, NavLink } from 'react-router-dom';
import '../style/training.css';

import Workforce from '../images/img1.png';
import Contingent from '../images/img2.png';
import bpo from '../images/img3.png';

// Data 
const data = [
  {
    id:8,
    id_name: 'BPO',
    image: bpo,
    title: 'BPO',
    desc_1: "We include payment processing, information technology services, quality assurance, etc. We also include marketing, sales, customer relations, and grievance redressal.",
    desc_2: "Instead of buying IT equipment and hiring more employees to do different tasks, NXT-Hire will reduce or even eliminate overhead costs. We also adopt best practices and use the latest technology. It naturally results in higher efficiency and greater productivity. Transferring non-core processes to us helps your organization more time to focus on your main business activities. With local market knowledge, national law expertise, or fluency in a foreign language we will help in boosting efficiency and quicker expansion."
  },
  {
    id:9,
    id_name: 'DomesticInternational',
    image: Workforce,
    title: 'Domestic & International IT Services',
    desc_1: "Focused on Information Technology, NXT-Hire is a major service provider when it comes to providing B2B companies with the tools and applications they need to effectively compete in the ever-evolving markets.",
    desc_2: " We are one of the major providers of integrated services through Application, BPO, Testing, and Analytics as well as enablers in the domains of Cloud, DevOps, and Information Security. We assist organizations with the tools and applications they need to effectively compete in the ever-changing and challenging market. At NXT-Hire, we enable an organization to seamlessly run an IT platform by integrating cognitive solutions, analytics, and automation with best-in-class IT services and people skills."
  },
  {
    id:10,
    id_name: 'Jobs',
    image: Contingent,
    title: 'Jobs',
    desc_1: "Our mission is to connect, empower, and support the advancement of all gender by building a gender-balanced workforce. We seek to embrace and value the varied intellectual competencies and the invaluable experiences that individuals possess. At NXT-Hire, we provide a fun, dynamic, performance-oriented work environment, based on the principles of mutual respect, open communication, trust, and mutual accountability.",
    desc_2: "We focus on the needs of our clients and our deployed workforce working as one to deliver on our promises and commitments. We understand the challenges of your current job profile and your busy schedule. With this in mind, NXT-Hire ensures that our experts are available on flexible days and times so that you can learn at your own flexible time. We enable our team to maximize their intellect by providing unlimited opportunities for growth."
  }
]



function training() {
  return <div className='solution'>

<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>Global Technology</h3>
  <p>At NXT-Hire, we think future-forward. We realize that technology is the bedrock of innovation and what drives tomorrow. So, we harness the power of technology to drive productivity and optimal efficiency into your business processes.</p>
  <p>Through our range of technology solutions that include BPO Services, Domestic and International IT Services, and Jobs solutions, we strive to deliver excellence across various facets of the enterprise.</p>
  </div>
  </div>
</div>

    <div className='container'>
       <div className='whyus-content'>
    
       {
       data.map(({id, id_name, image, title, desc_1,desc_2}) => {
         return (
       <div  key={id} className='row mt-5 mb-5' id={id_name}>
         <div className='whyus-content-div col-sm-5 col-12'>
            <div className='bg-img' style={{backgroundImage:`url(${image})`}}></div>
        </div>
        <div className='content-div col-sm-7 col-12'>
            <div className='detail'>
                <h3>{title}</h3>
                <p>{desc_1}</p>
                <p>{desc_2}</p>
                {/* <NavLink className="my-btn btn my-btn-2" to="/training">Get Free Training</NavLink>
                <NavLink className="my-btn btn" to="/contact">Contact Us</NavLink> */}
            </div>
        </div>
        </div>
         )
        }

        )}
     </div>
    </div>




  </div>
}

export default training