import React from 'react';
import { NavLink } from 'react-router-dom';
import '../style/training.css';



function about() {
  return <div className='solution'>

<div className='bg-img-div'>
 <div className='bg-img'></div>
  <div className='job-heading'>
  <div className='container'>
  <h3>About Us</h3>
  </div>
  </div>
</div>

    <div className='container'>
       <div className='whyus-content'>
    
      
       <div className='row mt-5 mb-5'>
         <div className='whyus-content-div col-sm-5 col-12'>
            <div className='bg-img'></div>
        </div>
        <div className='content-div col-sm-7 col-12'>
            <div className='detail'>
                <h3>About NXT-Hire</h3>
                <p>Our belief in the benefits of working closely with clients, as partners are reflected in everything we do and our goal is to be considered as an extension to your team rather than an independent third party. Each of our clients has a single point of contact and dedicated partner who will know and understand your business which in turn, will ensure that our input can help you to meet your long-term objectives. Consulting NXT-Hire prides itself on providing the highest quality services to businesses and their owners to support them in increasing and creating value.</p>
                <NavLink className="my-btn btn" to="/contact">Contact Us</NavLink>
            </div>
        </div>
        </div>

          <div className='about-job'>
                <h3>Want to join us</h3>
                <p>No matter what level you are at, Consulting NXT-Hire empowers you to lead, and to make a difference.<br/> Personal impact, mentoring, and network are just a few of the benefits of building a career at Consulting NXT-Hire.</p>
                <NavLink className="my-btn btn my-btn-2" to="/job">Join Us</NavLink>
            </div>


     </div>
    </div>




  </div>
}

export default about